import { yupResolver } from "@hookform/resolvers"
import PropTypes from "prop-types"
import React from "react"
import { Controller, useFieldArray, useWatch } from "react-hook-form"
import Select from "react-select"
import * as yup from "yup"
import HeadTitle from "@/components/HeadTitle"

import {
  Counter,
  InputError,
  Option,
  Options,
  PrimaryButton,
  RadioInput,
  Title1,
  Title2,
  OutlineButton,
} from "@/components/lib"
import FormComponent from "@/components/wizard/FormComponent"
import { useDeclarationForm } from "@/hooks/useDeclarationContext"
import { useScrollTop } from "@/hooks/useScrollTop"
import {
  ageOptions,
  authorTypesOptions,
  discernmentTroubles,
  genderOptions,
  healthJobOptions,
  isHealthType,
  isNoPersonType,
  isOtherType,
  ouiNonOptions,
  pursuitComplaintsByValues,
  pursuits,
  thirdPartyOptions,
  victimTypesOptions,
} from "@/utils/options"

import { ariaLiveMessagesFR, reactSelectCustomTheme } from "@/utils/select"

const schema = yup.object({
  authors: yup
    .array(
      yup.object({
        age: yup
          .object()
          .nullable()
          .when("type", (type, schema) => {
            return !isNoPersonType(type?.value)
              ? schema.required("L'âge est à renseigner")
              : schema
          }),
        discernmentTroubles: yup
          .array(yup.string())
          .when(
            "discernmentTroublesIsPresent",
            (discernmentTroublesIsPresent, schema) => {
              return discernmentTroublesIsPresent == "Oui"
                ? schema
                    .required("Préciser la nature du trouble")
                    .min(1, "Préciser la nature du trouble")
                : schema
            },
          ),
        discernmentTroublesIsPresent: yup
          .mixed()
          .oneOf(ouiNonOptions, "L'altération du discernement est à renseigner")
          .when("type", (type, schema) => {
            return !isNoPersonType(type?.value)
              ? schema.required("L'altération du discernement est à renseigner")
              : schema
          }),
        gender: yup
          .object()
          .nullable()
          .when("type", (type, schema) => {
            return !isNoPersonType(type?.value)
              ? schema.required("Le champ sexe est à renseigner")
              : schema
          }),
        healthJob: yup
          .object()
          .nullable()
          .when("type", (type, schema) => {
            return isHealthType(type?.value)
              ? schema.required("La profession de santé est à renseigner")
              : schema
          }),
        type: yup.object().nullable().required("Le type est à renseigner"),
      }),
    )
    .min(1, "Au moins un auteur est à renseigner")
    .required("Au moins un auteur est à renseigner"),
  pursuit: yup
    .mixed()
    .oneOf(pursuits, "Les suites judiciaires sont à renseigner")
    .required("Les suites judiciaires sont à renseigner"),
  pursuitBy: yup.array(yup.string()).when("pursuit", (pursuit, schema) => {
    return pursuit === "Plainte"
      ? schema
          .required("Préciser qui a déposé la plainte")
          .min(1, "Préciser qui a déposé la plainte")
      : schema
  }),
  thirdParty: yup
    .array(yup.string())
    .when("thirdPartyIsPresent", (thirdPartyIsPresent, schema) => {
      return thirdPartyIsPresent === "Oui"
        ? schema
            .required("Au moins un tiers est à renseigner")
            .min(1, "Au moins un tiers est à renseigner")
        : schema.transform(() => [])
    }),
  thirdPartyIsPresent: yup
    .mixed()
    .oneOf(ouiNonOptions, "Veuillez préciser si un tiers est intervenu")
    .required("Veuillez préciser si un tiers est intervenu"),
  thirdPartyPrecision: yup.string().when("thirdParty", (thirdParty, schema) => {
    return thirdParty?.includes("Autre")
      ? schema
          .required("Le champ Autre tiers doit être précisé")
          .min(1, "Le champ Autre tiers doit être précisé")
          .max(
            255,
            ({ max }) =>
              `Le champ Autre tiers ne doit pas dépasser ${max} caractères`,
          )
      : schema.transform(() => "")
  }),

  victims: yup
    .array(
      yup.object({
        age: yup
          .object()
          .nullable()
          .when("type", (type, schema) => {
            return !isNoPersonType(type?.value)
              ? schema.required("L'âge est à renseigner")
              : schema
          }),
        gender: yup
          .object()
          .nullable()
          .when("type", (type, schema) => {
            return !isNoPersonType(type?.value)
              ? schema.required("Le champ sexe est à renseigner")
              : schema
          }),
        healthJob: yup
          .object()
          .nullable()
          .when("type", (type, schema) => {
            return isHealthType(type?.value)
              ? schema.required("La profession de santé est à renseigner")
              : schema
          }),
        type: yup.object().nullable().required("Le type est à renseigner"),
      }),
    )
    .min(1, "Au moins une victime est à renseigner")
    .required("Au moins une victime est à renseigner"),
})

const customStyles = {
  container: (styles) => ({
    ...styles,
    flexGrow: 1,
  }),
  menu: (styles) => ({
    ...styles,
    textAlign: "left",
  }),
}
const suffix = (number, isFeminine = false) => {
  if (number === 1) {
    return isFeminine ? "ère" : "er"
  }
  if (number === 2) {
    return isFeminine ? "nde" : "nd"
  }
  return "ème"
}

const Victims = ({ control, errors }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "victims",
  })

  return (
    <div className="mt-2">
      <div>
        {fields.map((victim, index) => (
          <Victim
            key={victim?.id}
            data={victim}
            control={control}
            number={index}
            remove={() => remove(index)}
            errors={errors}
          />
        ))}
      </div>
      <div className="mt-10 text-center">
        <OutlineButton
          tabIndex={0}
          onClick={() => {
            append({ type: null })
          }}
        >
          <span aria-hidden="true">+</span>&nbsp;Ajouter une autre victime
        </OutlineButton>
      </div>
    </div>
  )
}

Victims.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object,
}

const Victim = ({ data, control, number = 0, remove, errors }) => {
  const type = useWatch({
    control,
    name: `victims[${number}].type`,
  })

  return (
    <div className="px-10 py-6 my-5 bg-blue-50 rounded-md shadow-md">
      <Title2 className="mb-8 float-left">
        {number + 1 + suffix(number + 1, true)} victime
      </Title2>
      {number > 0 && (
        <div className="inline-block float-right text-sm">
          <OutlineButton
            variant="red"
            onClick={remove}
            tabIndex={0}
            ariaLabel={"Supprimer la victime numéro " + number + 1}
          >
            <span className="align-middle">
              Effacer&nbsp;<span aria-hidden="true">X</span>
            </span>
          </OutlineButton>
        </div>
      )}{" "}
      <div className="mt-16">
        <b className="mandatory">Profil</b>
        <div className="flex space-x-6">
          <div className="flex-1 max-w-xs">
            <label
              className="block mb-2 text-xs font-medium tracking-wide text-gray-700 uppercase"
              htmlFor={`victims[${number}].type`}
            >
              &nbsp;
            </label>

            <div className="w-80">
              <Controller
                as={Select}
                control={control}
                name={`victims[${number}].type`}
                inputId={`victims[${number}].type`}
                id={`victims[${number}].type`}
                instanceId={`victims[${number}].type`}
                options={victimTypesOptions}
                placeholder="Choisir..."
                isClearable={true}
                styles={customStyles}
                defaultValue={type || null}
                aria-invalid={!!errors?.victims?.[number]?.type?.message}
                aria-required={true}
                aria-label="Profil (obligatoire)"
                ariaLiveMessages={ariaLiveMessagesFR}
                theme={reactSelectCustomTheme}
                noOptionsMessage={() => "Aucun élément"}
              />

              <InputError error={errors?.victims?.[number]?.type?.message} />
            </div>
          </div>
          {!isNoPersonType(type?.value) && (
            <>
              <div className="flex-1">
                <label
                  className="block mb-2 text-xs font-medium tracking-wide text-gray-700 uppercase mandatory"
                  htmlFor={`victims[${number}].gender`}
                >
                  de sexe
                </label>
                <Controller
                  as={Select}
                  control={control}
                  name={`victims[${number}].gender`}
                  inputId={`victims[${number}].gender`}
                  id={`victims[${number}].gender`}
                  instanceId={`victims[${number}].gender`}
                  options={genderOptions}
                  placeholder="Choisir..."
                  isClearable={true}
                  styles={customStyles}
                  defaultValue={data?.gender || null}
                  aria-invalid={!!errors?.victims?.[number]?.gender?.message}
                  aria-required={true}
                  aria-label="Sexe (obligatoire)"
                  ariaLiveMessages={ariaLiveMessagesFR}
                  theme={reactSelectCustomTheme}
                  noOptionsMessage={() => "Aucun élément"}
                />

                <InputError
                  error={errors?.victims?.[number]?.gender?.message}
                />
              </div>
              <div className="flex-1">
                <label
                  className="block mb-2 text-xs font-medium tracking-wide text-gray-700 uppercase mandatory"
                  htmlFor={`victims[${number}].age`}
                >
                  et âgé de
                </label>
                <Controller
                  as={Select}
                  control={control}
                  name={`victims[${number}].age`}
                  inputId={`victims[${number}].age`}
                  id={`victims[${number}].age`}
                  instanceId={`victims[${number}].age`}
                  options={ageOptions}
                  placeholder="Choisir..."
                  isClearable={true}
                  styles={customStyles}
                  defaultValue={data?.age || null}
                  aria-invalid={!!errors?.victims?.[number]?.age?.message}
                  aria-required={true}
                  aria-label="Âge (obligatoire)"
                  ariaLiveMessages={ariaLiveMessagesFR}
                  theme={reactSelectCustomTheme}
                  noOptionsMessage={() => "Aucun élément"}
                />

                <InputError error={errors?.victims?.[number]?.age?.message} />
              </div>
            </>
          )}
        </div>
      </div>
      {isHealthType(type?.value) && (
        <div className="flex mt-6">
          <div className="flex items-center flex-1 text-center">
            <label
              className="justify-center flex-1 text-xs font-medium text-gray-700 uppercase mandatory"
              htmlFor={`victims[${number}].type`}
            >
              dont la profession est&nbsp;
            </label>
          </div>
          <div className="flex-1">
            <div className="">
              <Controller
                as={Select}
                control={control}
                name={`victims[${number}].healthJob`}
                inputId={`victims[${number}].healthJob`}
                id={`victims[${number}].healthJob`}
                instanceId={`victims[${number}].healthJob`}
                options={healthJobOptions}
                placeholder="Choisir..."
                isClearable={true}
                styles={customStyles}
                defaultValue={data?.type || null}
                aria-invalid={!!errors?.victims?.[number]?.healthJob?.message}
                aria-required={true}
                aria-label="Profession (obligatoire)"
                ariaLiveMessages={ariaLiveMessagesFR}
                theme={reactSelectCustomTheme}
                noOptionsMessage={() => "Aucun élément"}
              />

              <InputError
                error={errors?.victims?.[number]?.healthJob?.message}
              />
            </div>
          </div>
        </div>
      )}
      {!isNoPersonType(type?.value) && (
        <div className="mt-8">
          <b>Conséquences d’éventuelles blessures physiques et/ou psychiques</b>
          <div className="flex items-center justify-around mt-8">
            <div className="text-center">
              <Controller
                as={Counter}
                control={control}
                name={`victims[${number}].sickLeaveDays`}
                defaultValue={data?.sickLeaveDays || 0}
              />
              {" jours d'arrêt de travail"}
            </div>
            <div className="text-center">
              <Controller
                as={Counter}
                control={control}
                name={`victims[${number}].hospitalizationDays`}
                defaultValue={data?.hospitalizationDays || 0}
              />
              {" jours d'hospitalisation"}
            </div>
            <div className="text-center">
              <Controller
                as={Counter}
                control={control}
                name={`victims[${number}].ITTDays`}
                defaultValue={data?.ITTDays || 0}
                aria-label="Jours d'I T T"
              />
              {" jours d'ITT"}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

Victim.propTypes = {
  control: PropTypes.object,
  data: PropTypes.object.isRequired,
  errors: PropTypes.object,
  number: PropTypes.number,
  remove: PropTypes.func,
}

const Authors = ({ control, register, errors }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "authors",
  })

  return (
    <div className="mt-2">
      <div>
        {fields.map((author, index) => (
          <Author
            key={author?.id}
            data={author}
            control={control}
            number={index}
            remove={() => remove(index)}
            register={register}
            errors={errors}
          />
        ))}
      </div>
      <div className="mt-10 text-center">
        <OutlineButton
          tabIndex={0}
          onClick={() => {
            append({})
          }}
        >
          <span aria-hidden="true">+</span>&nbsp;Ajouter un autre auteur
        </OutlineButton>
      </div>
    </div>
  )
}

Authors.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object,
  register: PropTypes.func.isRequired,
}

const Author = ({ data, control, number = 0, remove, register, errors }) => {
  const type = useWatch({
    control,
    name: `authors[${number}].type`,
  })

  const discernmentTroublesIsPresent = useWatch({
    control,
    name: `authors[${number}].discernmentTroublesIsPresent`,
  })

  return (
    <div className="px-10 py-6 my-5 bg-blue-50 rounded-md shadow-md">
      <Title2 className="mb-8">
        {number + 1 + suffix(number + 1)} auteur
        {number > 0 && (
          <div className="inline-block float-right text-sm">
            <OutlineButton
              variant="red"
              onClick={remove}
              tabIndex={0}
              ariaLabel={"Supprimer l'auteur numéro " + number + 1}
            >
              <span className="align-middle">
                Effacer&nbsp;<span aria-hidden="true">X</span>
              </span>
            </OutlineButton>
          </div>
        )}
      </Title2>

      <b className="mandatory">Profil</b>

      <div className="flex space-x-6">
        <div className="flex-1 max-w-xs">
          <label
            className="block mb-2 text-xs font-medium tracking-wide text-gray-700 uppercase"
            htmlFor={`authors[${number}].type`}
          >
            &nbsp;
          </label>

          <div className="w-80">
            <Controller
              as={Select}
              control={control}
              name={`authors[${number}].type`}
              inputId={`authors[${number}].type`}
              id={`authors[${number}].type`}
              instanceId={`authors[${number}].type`}
              options={authorTypesOptions}
              placeholder="Choisir..."
              isClearable={true}
              styles={customStyles}
              defaultValue={data?.type || null}
              aria-invalid={!!errors?.authors?.[number]?.type?.message}
              aria-required={true}
              aria-label="Profil (obligatoire)"
              ariaLiveMessages={ariaLiveMessagesFR}
              theme={reactSelectCustomTheme}
              noOptionsMessage={() => "Aucun élément"}
            />

            <InputError error={errors?.authors?.[number]?.type?.message} />
          </div>
        </div>

        {!isNoPersonType(type?.value) && (
          <>
            <div className="flex-1">
              <label
                className="block mb-2 text-xs font-medium tracking-wide text-gray-700 uppercase mandatory"
                htmlFor={`authors[${number}].gender`}
              >
                de sexe
              </label>
              <Controller
                as={Select}
                control={control}
                name={`authors[${number}].gender`}
                inputId={`authors[${number}].gender`}
                id={`authors[${number}].gender`}
                instanceId={`authors[${number}].gender`}
                options={genderOptions}
                placeholder="Choisir..."
                isClearable={true}
                styles={customStyles}
                defaultValue={data?.gender || null}
                aria-invalid={!!errors?.authors?.[number]?.gender?.message}
                aria-required={true}
                aria-label="Sexe (obligatoire)"
                ariaLiveMessages={ariaLiveMessagesFR}
                theme={reactSelectCustomTheme}
                noOptionsMessage={() => "Aucun élément"}
              />

              <InputError error={errors?.authors?.[number]?.gender?.message} />
            </div>
            <div className="flex-1">
              <label
                className="block mb-2 text-xs font-medium tracking-wide text-gray-700 uppercase mandatory"
                htmlFor={`authors[${number}].age`}
              >
                et âgé de
              </label>
              <Controller
                as={Select}
                control={control}
                name={`authors[${number}].age`}
                inputId={`authors[${number}].age`}
                id={`authors[${number}].age`}
                instanceId={`authors[${number}].age`}
                options={ageOptions}
                placeholder="Choisir..."
                isClearable={true}
                styles={customStyles}
                defaultValue={data?.age || null}
                aria-invalid={!!errors?.authors?.[number]?.age?.message}
                aria-required={true}
                aria-label="Âge (obligatoire)"
                ariaLiveMessages={ariaLiveMessagesFR}
                theme={reactSelectCustomTheme}
                noOptionsMessage={() => "Aucun élément"}
              />

              <InputError error={errors?.authors?.[number]?.age?.message} />
            </div>
          </>
        )}
      </div>
      {isNoPersonType(type?.value) && (
        <>
          <p className="flex-1 mt-6 text-xs font-medium text-gray-700 uppercase">
            « Inconnu » signifie que la victime n’a pas vu l’auteur commettre la
            violence (elle ne sait pas s’il s’agit d’un homme ou d’une femme. Ex
            : à la suite de la constatation d’un vol, d’une dégradation...) et
            non qu’elle ne connaît pas son identité. En revanche, si la victime
            a vu l’auteur mais qu’il ne fait pas partie d’une des catégories du
            profil auteur, cocher alors « Autre » et indiquer sexe et âge
          </p>
        </>
      )}
      {isOtherType(type?.value) && (
        <>
          <div className="flex-1 mt-6 text-xs font-medium text-gray-700 uppercase">
            « Autre » signifie que la victime a vu qui est l’auteur de la
            violence (homme, femme) mais qu’il ne fait pas partie des profils
            déjà indiqués. Ex : vous avez été agressé sur la voie publique par
            un homme ou une femme en vous rendant au domicile d’un patient. Vous
            avez subi un vol à main armée dans l’officine par un homme ou une
            femme.
          </div>
        </>
      )}

      {isHealthType(type?.value) && (
        <div className="flex mt-6">
          <div className="flex items-center flex-1 text-center">
            <label
              className="justify-center flex-1 text-xs font-medium text-gray-700 uppercase mandatory"
              htmlFor={`authors[${number}].type`}
            >
              dont la profession est&nbsp;
            </label>
          </div>
          <div className="flex-1">
            <div className="">
              <Controller
                as={Select}
                control={control}
                name={`authors[${number}].healthJob`}
                inputId={`authors[${number}].healthJob`}
                id={`authors[${number}].healthJob`}
                instanceId={`authors[${number}].healthJob`}
                options={healthJobOptions}
                placeholder="Choisir..."
                isClearable={true}
                styles={customStyles}
                defaultValue={data?.type || null}
                aria-invalid={!!errors?.authors?.[number]?.healthJob?.message}
                aria-required={true}
                aria-label="Profession (obligatoire)"
                ariaLiveMessages={ariaLiveMessagesFR}
                theme={reactSelectCustomTheme}
                noOptionsMessage={() => "Aucun élément"}
              />

              <InputError
                error={errors?.authors?.[number]?.healthJob?.message}
              />
            </div>
          </div>
        </div>
      )}
      {!isNoPersonType(type?.value) && (
        <div className="mt-12">
          <div className="flex">
            <fieldset aria-required="true">
              <b className="mr-8">
                <legend className="mandatory">
                  Altération du discernement
                </legend>
              </b>

              <div className="inline-flex flex-row items-end space-x-8">
                <RadioInput
                  name={`authors[${number}].discernmentTroublesIsPresent`}
                  value="Oui"
                  register={register()}
                  defaultChecked={data?.discernmentTroublesIsPresent === "Oui"}
                />
                <RadioInput
                  name={`authors[${number}].discernmentTroublesIsPresent`}
                  value="Non"
                  register={register()}
                  defaultChecked={data?.discernmentTroublesIsPresent === "Non"}
                />
              </div>
            </fieldset>
          </div>

          <InputError
            error={
              errors?.authors?.[number]?.discernmentTroublesIsPresent?.message
            }
          />

          {discernmentTroublesIsPresent === "Oui" && (
            <>
              <fieldset aria-required="true">
                <legend className="italic text-gray-600 mandatory">
                  Nature du trouble : Plusieurs choix possibles
                </legend>

                <Options
                  name={`authors[${number}].discernmentTroubles`}
                  register={register()}
                  allChecked={data?.discernmentTroubles}
                >
                  {discernmentTroubles.map((value) => (
                    <Option key={value} value={value} />
                  ))}
                </Options>
              </fieldset>

              <InputError
                error={errors?.authors?.[number]?.discernmentTroubles?.message}
              />
            </>
          )}
        </div>
      )}
    </div>
  )
}

Author.propTypes = {
  control: PropTypes.object,
  data: PropTypes.object.isRequired,
  errors: PropTypes.object,
  number: PropTypes.number,
  register: PropTypes.func,
  remove: PropTypes.func,
}

const Step4Page = () => {
  useScrollTop()
  const {
    onSubmit: originalOnSubmit,
    handleSubmit,
    errors,
    control,
    setValue,
    getValues,
    state,
    watch,
    register,
  } = useDeclarationForm({
    defaultValuesFromState: (state) => ({
      authors: state?.steps?.victimsAuthors?.authors || [{}],
      pursuit: state?.steps?.victimsAuthors?.pursuit,
      pursuitBy: state?.steps?.victimsAuthors?.pursuitBy || [],
      pursuitPrecision: state?.steps?.victimsAuthors?.pursuitPrecision,
      discernmentTroubles: state?.steps?.victimsAuthors?.discernmentTroubles,
      discernmentTroublesIsPresent:
        state?.steps?.victimsAuthors?.discernmentTroublesIsPresent,
      thirdParty: state?.steps?.victimsAuthors?.thirdParty,
      thirdPartyIsPresent: state?.steps?.victimsAuthors?.thirdPartyIsPresent,
      thirdPartyPrecision: state?.steps?.victimsAuthors?.thirdPartyPrecision,
      victims: state?.steps?.victimsAuthors?.victims || [{}],
    }),
    resolver: yupResolver(schema),
  })

  const [phase, setPhase] = React.useState(1)

  const watchPursuit = watch("pursuit")
  const watchThirdParty = watch("thirdParty")
  const watchThirdPartyIsPresent = watch("thirdPartyIsPresent")

  React.useEffect(() => {
    // Si le champ pursuit est rempli, c'est qu'on n'affiche pas la page pour la 1ère fois, i.e. tout doit être déplié
    if (state?.steps?.victimsAuthors?.pursuit) setPhase(3)
  }, [state?.steps?.victimsAuthors?.pursuit, setPhase])

  React.useEffect(() => {
    // Clean precision when pursuit has changed and is not equal to Autre
    if (watchPursuit !== "Autre") {
      setValue("pursuitPrecision", "")
    }
  }, [setValue, watchPursuit])

  React.useEffect(() => {
    // Clean precision when thirdParty has changed and is not equal to Autre
    if (watchThirdParty?.length && !watchThirdParty?.includes("Autre")) {
      setValue("thirdPartyPrecision", "")
    }
  }, [setValue, watchThirdParty])

  const onSubmit = (data) => {
    // We can't do it in yup validation (with transform) because this part of the form is not present so it is not carry on by react hook form...
    if (data?.pursuit !== "Plainte") data.pursuitBy = []
    if (data?.thirdPartyIsPresent === "Non") data.thirdParty = []
    if (data?.discernmentTroublesIsPresent === "Non")
      data.discernmentTroubles = []

    originalOnSubmit(data)
  }

  return (
    <FormComponent
      onSubmit={handleSubmit(onSubmit)}
      title={
        <span>
          Qui a été <b>victime</b> ?
        </span>
      }
    >
      <HeadTitle title="Déclaration - Étape 4 - Victimes et auteurs" />

      <p className="mt-10 italic mandatory">
        {"Les champs obligatoires sont marqués d'un astérisque"}
      </p>
      <>
        <Victims control={control} errors={errors} />
        <fieldset aria-required="true">
          <Title1 className="mt-12">
            <legend className="mandatory">
              {"Y'a-t-il eu des suites judiciaires ?"}
            </legend>
          </Title1>
          <div className="mt-4">
            <div className="block mt-3">
              <div className="mt-2 space-y-2">
                {pursuits.map((pursuit) => (
                  <RadioInput
                    key={pursuit}
                    name="pursuit"
                    value={pursuit}
                    register={register}
                  />
                ))}

                <InputError error={errors?.pursuit?.message} />
              </div>

              {watchPursuit === "Plainte" && (
                <div>
                  <fieldset aria-required="true">
                    <Title2 className="mt-12">
                      <legend className="mandatory">Par...</legend>
                    </Title2>

                    <Options name="pursuitBy" register={register}>
                      {pursuitComplaintsByValues.map((value) => (
                        <Option key={value} value={value} />
                      ))}
                    </Options>

                    <InputError error={errors?.pursuitBy?.message} />
                  </fieldset>
                </div>
              )}
            </div>
          </div>
        </fieldset>
      </>
      {phase === 1 && (
        <div className="flex justify-center w-full my-16 space-x-4">
          <PrimaryButton onClick={() => setPhase(2)}>
            {"Suivant : description de l'auteur"}
          </PrimaryButton>
        </div>
      )}
      {phase >= 2 && (
        <>
          <Title1 className="mt-16">
            Qui a été <b>auteur</b> de la violence ?
          </Title1>
          <Authors control={control} register={register} errors={errors} />
          {phase === 2 && (
            <div className="flex justify-center w-full my-16 space-x-4">
              <PrimaryButton onClick={() => setPhase(3)}>
                Suivant : intervention de tiers
              </PrimaryButton>
            </div>
          )}
        </>
      )}

      {phase === 3 && (
        <>
          <Title1 className="mt-16 mandatory">
            {"D'autres personnes ont-elles été impliquées ?"}
          </Title1>
          <Title2 className="flex mt-8 mb-2">
            <fieldset aria-required="true">
              <span className="mr-8">
                <legend>Intervention de tiers</legend>
              </span>
              <div className="inline-flex flex-row items-end space-x-8">
                <RadioInput
                  name="thirdPartyIsPresent"
                  value="Oui"
                  register={register}
                />
                <RadioInput
                  name="thirdPartyIsPresent"
                  value="Non"
                  register={register}
                />
              </div>
            </fieldset>
          </Title2>

          <InputError error={errors?.thirdPartyIsPresent?.message} />

          {watchThirdPartyIsPresent === "Oui" && (
            <>
              <fieldset aria-required="true">
                <legend className="text-gray-600 italic mandatory">
                  Profession du tiers : plusieurs choix possibles
                </legend>

                <Options
                  name="thirdParty"
                  register={register}
                  setValue={setValue}
                  getValues={getValues}
                >
                  {thirdPartyOptions.map((option) => (
                    <Option key={option.value} {...option} />
                  ))}
                </Options>
              </fieldset>

              <InputError error={errors?.thirdParty?.message} />
            </>
          )}
        </>
      )}
    </FormComponent>
  )
}

export default Step4Page
