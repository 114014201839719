import { yupResolver } from "@hookform/resolvers"
import { formatISO, isFuture, parseISO } from "date-fns"
import React, { useEffect, useState } from "react"
import { Controller } from "react-hook-form"
import Select from "react-select"
import * as yup from "yup"

import HeadTitle from "@/components/HeadTitle"

import { InputError, RadioInput, Title2 } from "@/components/lib"
import FormComponent from "@/components/wizard/FormComponent"
import { useDeclarationForm } from "@/hooks/useDeclarationContext"
import { useScrollTop } from "@/hooks/useScrollTop"

import { selectConfig } from "../../../../config"
import TownSelect from "@/components/TownSelect"
import {
  hoursOptions,
  insideLiberalLocations,
  outsideLiberalLocations,
} from "@/utils/options"

import { ariaLiveMessagesFR, reactSelectCustomTheme } from "@/utils/select"
import fetcher from "@/utils/fetcher"
import { API_URL } from "@/utils/config"

const schema = yup.object().shape({
  date: yup
    .string()
    .required("La date est à renseigner")
    .test(
      "past or present ISO date representation",
      "La date ne peut pas être future",
      function (value) {
        const date = parseISO(value)
        if (date === "Invalid Date") return false
        return !isFuture(date)
      },
    ),
  hour: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string(),
    })
    .nullable(true) // to consider null as an object and let required validate and displays the appropriate message
    .required("Les heures sont à renseigner"),
  location: yup.string().nullable(true).required("Le lieu est à renseigner"),
  locationOfficine: yup.string().when("location", {
    is: "Officine",
    then: yup
      .string()
      .nullable(true)
      .required("Le champ en situation de garde est à renseigner"),
  }),
  locationOfficineFilter: yup.string().when("locationOfficine", {
    is: "Oui",
    then: yup
      .string()
      .nullable(true)
      .required(
        "Le champ filtrage réalisé par les forces de l'ordre lors de la garde est à renseigner",
      ),
  }),
  locationSurveillance: yup.string().when("location", {
    is: "Officine",
    then: yup
      .string()
      .nullable(true)
      .required("Le champ dispositif de surveillance est à renseigner"),
  }),
  otherLocation: yup.string().when("location", {
    is: "Autre",
    then: yup
      .string()
      .required('Le champ "Autre lieu" doit être précisé')
      .max(
        255,
        ({ max }) =>
          `Le champ "Autre lieu" ne doit pas dépasser ${max} caractères`,
      ),
  }),
  town: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string(),
    })
    .nullable(true) // to consider null as an object and let required validate and displays the appropriate message
    .required("La ville est à renseigner"),
})

const Step1 = () => {
  const [limitDate, setLimitDate] = useState("")

  useScrollTop()
  const { onSubmit, handleSubmit, errors, control, setValue, watch, register } =
    useDeclarationForm({
      defaultValuesFromState: (state) => ({
        date:
          state?.steps?.dateLocation?.date ||
          formatISO(new Date(), { representation: "date" }),
        hour: state?.steps?.dateLocation?.hour || hoursOptions?.[0],
        location: state?.steps?.dateLocation?.location,
        locationOfficine: state?.steps?.dateLocation?.locationOfficine,
        locationOfficineFilter:
          state?.steps?.dateLocation?.locationOfficineFilter || null,
        locationSurveillance: state?.steps?.dateLocation?.locationSurveillance,
        otherLocation: state?.steps?.dateLocation?.otherLocation,
        town: state?.steps?.dateLocation?.town || null,
      }),

      resolver: yupResolver(schema),
    })

  const location = watch("location")
  const locationOfficine = watch("locationOfficine")

  const getAllParams = async () => {
    try {
      const { data } = await fetcher(`${API_URL}/params`, {
        headers: { "Content-Type": "application/json" },
        method: "GET",
      })
      const params = data.reduce((prev, curr) => {
        return { ...prev, [curr.name]: curr.value }
      }, {})
      setLimitDate(Object.values(params)[0])
    } catch (e) {
      console.error("Error Get Params:", e)
    }
  }

  useEffect(() => {
    // Clean otherLocation when location has changed and is not equal to Autre
    if (location !== "Autre") {
      setValue("otherLocation", "")
    }
    if (location !== "Officine") {
      setValue("locationOfficine", null)
      setValue("locationOfficineFilter", null)
      setValue("locationSurveillance", null)
    }
  }, [setValue, location])

  useEffect(() => {
    getAllParams()
  }, [])

  return (
    <FormComponent
      onSubmit={handleSubmit(onSubmit)}
      title="Où la violence a-t-elle eu lieu ?"
    >
      <HeadTitle title="Déclaration - Étape 1 - Date et lieu" />

      <p className="mt-10 italic mandatory">
        {"Les champs obligatoires sont marqués d'un astérisque"}
      </p>
      <fieldset aria-required="true">
        <Title2 className="mt-12 mb-8">
          <legend>
            {"Quand et dans quelle ville l'événement s'est-il déroulé ?"}
          </legend>
        </Title2>

        <div className="flex mt-4 space-x-6">
          <div className="flex-1">
            <label
              className="block mb-2 text-xs font-medium tracking-wide text-gray-700 uppercase mandatory"
              htmlFor="date"
            >
              Date
            </label>
            <input
              className="w-full form-input"
              min={limitDate}
              type="date"
              id="date"
              name="date"
              ref={register}
              aria-invalid={!!errors?.date?.message}
              aria-required="true"
            />

            <InputError error={errors?.date?.message} />
          </div>

          <div className="flex-1">
            <label
              className="block mb-2 text-xs font-medium tracking-wide text-gray-700 uppercase mandatory"
              htmlFor="hour"
            >
              Horaire
            </label>

            <Controller
              aria-required={true}
              aria-label="Horaire (obligatoire)"
              as={Select}
              options={hoursOptions}
              name="hour"
              inputId="hour"
              control={control}
              styles={selectConfig}
              required
              ariaLiveMessages={ariaLiveMessagesFR}
              theme={reactSelectCustomTheme}
              noOptionsMessage={() => "Aucun élément"}
            />
          </div>

          <div className="flex-1">
            <label
              className={`block mb-2 text-xs font-medium tracking-wide text-gray-700 uppercase mandatory ${
                errors?.town && "text-red-500"
              }`}
              htmlFor="town"
            >
              Ville
            </label>

            <TownSelect
              name="town"
              ariaLabel="Ville (obligatoire)"
              control={control}
            />

            <InputError error={errors?.town?.message} />
          </div>
        </div>
      </fieldset>

      <fieldset aria-required="true">
        <Title2 className="mt-12">
          <legend className="mandatory">Dans quel lieu précisément ?</legend>
        </Title2>
        <div className="mt-4">
          <fieldset>
            <legend>
              <b>Intérieur</b>
            </legend>
            <div className="block mt-3">
              <div className="mt-2 space-y-2">
                {insideLiberalLocations.map((value) => (
                  <div key={value}>
                    <RadioInput
                      key={value}
                      name="location"
                      value={value}
                      register={register}
                    />

                    {location === "Officine" && value === "Officine" ? (
                      <div>
                        <p className="my-1 font-medium">
                          En situation de garde :{" "}
                        </p>
                        <div className="flex">
                          {["Oui", "Non"].map((value) => (
                            <RadioInput
                              className="ml-3"
                              key={value}
                              name="locationOfficine"
                              value={value}
                              register={register}
                            />
                          ))}
                        </div>
                        {locationOfficine === "Oui" ? (
                          <>
                            <p className="my-1 font-medium">
                              Filtrage réalisé par les forces de l&apos;ordre
                              lors de la garde :{" "}
                            </p>
                            <div className="flex">
                              {["Oui", "Non"].map((value) => (
                                <RadioInput
                                  className="ml-3"
                                  key={value}
                                  name="locationOfficineFilter"
                                  value={value}
                                  register={register}
                                />
                              ))}
                            </div>
                          </>
                        ) : null}
                        <p className="my-2 font-medium">
                          Dispositif de surveillance :{" "}
                        </p>
                        <div className="flex">
                          {["Vigile", "Vidéosurveillance", "Rien"].map(
                            (value) => (
                              <RadioInput
                                className="ml-3"
                                key={value}
                                name="locationSurveillance"
                                value={value}
                                register={register}
                              />
                            ),
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
          </fieldset>
        </div>

        <div className="mt-8">
          <fieldset>
            <legend>
              <b>Extérieur</b>
            </legend>
            <div className="block mt-3">
              <div className="mt-2 space-y-2">
                {outsideLiberalLocations.map((value) => (
                  <RadioInput
                    key={value}
                    name="location"
                    value={value}
                    register={register}
                  />
                ))}

                <div>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="form-radio"
                      name="location"
                      value="Autre"
                      ref={register}
                    />
                    <span className="ml-2">{"Autre : "}</span>
                  </label>
                  <div
                    className={`inline-block py-2 border-b-2  ${
                      errors?.otherLocation?.message
                        ? "border-red-500"
                        : "border-blue-400"
                    }`}
                  >
                    <input
                      className={`px-2 mr-3 leading-tight bg-transparent border-none focus:outline-none`}
                      type="text"
                      id="otherLocation"
                      name="otherLocation"
                      placeholder="Ajouter un lieu"
                      onChange={() => setValue("location", "Autre")}
                      ref={register}
                      aria-invalid={
                        errors?.otherLocation?.message ? "true" : "false"
                      }
                    />
                  </div>
                </div>

                <InputError error={errors?.otherLocation?.message} />
              </div>
            </div>
          </fieldset>
        </div>
      </fieldset>
    </FormComponent>
  )
}

export default Step1
