import { yupResolver } from "@hookform/resolvers"
import React from "react"
import * as yup from "yup"

import HeadTitle from "@/components/HeadTitle"
import { Title2 } from "@/components/lib"
import FormComponent from "@/components/wizard/FormComponent"
import { useDeclarationForm } from "@/hooks/useDeclarationContext"
import { useScrollTop } from "@/hooks/useScrollTop"
import Links from "@/components/LinksStep5"

const schema = yup.object({
  description: yup.string().required("La description doit être renseignée"),
})

const Step5Page = () => {
  useScrollTop()
  const { onSubmit, handleSubmit, errors, register } = useDeclarationForm({
    defaultValuesFromState: (state) => ({
      description: state?.steps?.precision?.description,
    }),

    resolver: yupResolver(schema),
  })

  return (
    <FormComponent
      onSubmit={handleSubmit(onSubmit)}
      title="Pourriez-vous apporter quelques précisions ?"
    >
      <HeadTitle title="Déclaration - Étape 5 - Précisions" />
      <p className="mt-10 italic mandatory">
        {"Les champs obligatoires sont marqués d'un astérisque"}
      </p>
      <Title2 className="mt-8 mb-4 mandatory">
        {"Description plus détaillée de l'événement"}
      </Title2>

      <div className="border p-3 font-bold rounded border-4 border-red-700 align-middle mb-6">
        <em className="text-blue-700" id="precisions">
          <p className="text-center">ATTENTION ! </p>
          <p>
            Dans la description de l’événement ne faites figurer aucune mention
            permettant d’identifier une personne : prénom, nom de famille,
            surnom, date et lieu de naissance, un quelconque numéro (téléphone,
            plaque d’immatriculation, etc.). Vous pouvez formuler ainsi : « M.
            ou Mme, le patient ou la patiente, le fils/la fille du patient, le
            Dr X, l’IDE Y, etc. a fait ceci, a dit cela ».
          </p>
          <br />
          <p>
            Ajouter si possible votre ressenti, le contexte, les gestes,
            attitudes et comportements ainsi que les expressions et les mots
            précis prononcés pour intimider, insulter, outrager, menacer,
            violenter.
          </p>
        </em>
      </div>
      <label htmlFor="description" className="italic">
        {
          "Description de l'événement en ajoutant, si besoin, votre ressenti et le contexte."
        }
      </label>
      <textarea
        className={`fr-input w-full mt-8 form-textarea rounded ${
          errors?.description?.message ? "border-red-500" : "border-gray-300"
        }`}
        name="description"
        ref={register}
        style={{ height: "300px" }}
        id="description"
        aria-describedby="precisions"
        aria-required="true"
      />
      <Links />
      <p className="mt-10 text-md font-bold">
        Attention ! Une fois envoyée, votre déclaration ne sera plus modifiable.
      </p>
    </FormComponent>
  )
}

export default Step5Page
