import { Title2 } from "./lib"

const Links = () => (
  <>
    <Title2 className="mt-10 font-bold">
      {"Pour vous aider dans vos démarches judiciaires et de sécurisation"}
    </Title2>
    <div className="mt-4">
      <ul className="list-disc px-10">
        <li>
          <a
            href="https://solidarites-sante.gouv.fr/professionnels/ameliorer-les-conditions-d-exercice/observatoire-national-des-violences-en-milieu-de-sante/dgos-onvs-documentation-pratique"
            target="_blank"
            rel="noreferrer"
            className="text-blue-600 hover:filter-none hover:text-blue-600"
          >
            Conseils et documentation pratiques (sante.gouv.fr)
          </a>
        </li>
        <li>
          <a
            href="https://solidarites-sante.gouv.fr/IMG/pdf/protection_penale_specifique_personnels_de_sante_-_conduite_a_tenir_ets_cabinet_officine_2022-11-18_v3.pdf"
            target="_blank"
            rel="noreferrer"
            className="text-blue-600 hover:filter-none hover:text-blue-600"
          >
            La protection pénale spécifique dont vous bénéficiez en tant que
            professionnel de santé ou personne chargée d’une mission de service
            public (sante.gouv.fr)
          </a>
        </li>
        <li>
          <a
            href="https://sante.gouv.fr/IMG/pdf/comment_apporter_la_preuve_d_une_violence_verbale_2023-01.pdf"
            target="_blank"
            rel="noreferrer"
            className="text-blue-600 hover:filter-none hover:text-blue-600"
          >
            Comment apporter la preuve d&apos;une violence verbale ?
            (sante.gouv.fr)
          </a>
        </li>
        <br />
        <li>
          <a
            href="https://www.masecurite.interieur.gouv.fr/fr/fiches-pratiques/famille-et-aides-aux-victimes/main-courante"
            target="_blank"
            rel="noreferrer"
            className="text-blue-600 hover:filter-none hover:text-blue-600"
          >
            Qu&apos;est-ce qu&apos;une main courante | Ma Sécurité
            (interieur.gouv.fr)
          </a>
        </li>
        <li>
          <a
            href="https://www.masecurite.interieur.gouv.fr/fr/fiches-pratiques/famille-et-aides-aux-victimes/comment-se-passe-depot-plainte-d-une-victime-de-violences-sexuelles-et-sexistes"
            target="_blank"
            rel="noreferrer"
            className="text-blue-600 hover:filter-none hover:text-blue-600"
          >
            Comment se passe le dépôt de plainte d&apos;une victime de violences
            sexuelles et sexistes | Ma Sécurité (interieur.gouv.fr)
          </a>
        </li>
        <li>
          <a
            href="https://www.masecurite.interieur.gouv.fr/fr"
            target="_blank"
            rel="noreferrer"
            className="text-blue-600 hover:filter-none hover:text-blue-600"
          >
            Ma Sécurité | Ma Sécurité (interieur.gouv.fr)
          </a>
        </li>
        <li>
          <a
            href="https://www.service-public.fr/particuliers/vosdroits/F1435"
            target="_blank"
            rel="noreferrer"
            className="text-blue-600 hover:filter-none hover:text-blue-600"
          >
            Le dépôt de plainte auprès de la police, de la gendarmerie ou du
            procureur de la République (service-public.fr)
          </a>
        </li>
        <li>
          <a
            href="https://www.pre-plainte-en-ligne.gouv.fr/"
            target="_blank"
            rel="noreferrer"
            className="text-blue-600 hover:filter-none hover:text-blue-600"
          >
            La pré-plainte en ligne (atteinte aux biens : vols, dégradations,
            escroqueries... avec auteur inconnu) (interieur.gouv.fr)
          </a>
        </li>
        <br />
        <li>
          <a
            href="http://www.annuaires.justice.gouv.fr/annuaires-12162/annuaire-des-associations-daide-aux-victimes-21769.html"
            target="_blank"
            rel="noreferrer"
            className="text-blue-600 hover:filter-none hover:text-blue-600"
          >
            Annuaire des associations d&apos;aide aux victimes
          </a>
        </li>
      </ul>
    </div>
  </>
)

export default Links
