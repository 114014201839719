import { yupResolver } from "@hookform/resolvers"
import React from "react"
import * as yup from "yup"

import HeadTitle from "@/components/HeadTitle"
import { Group, Groups, Option, Options, Title2 } from "@/components/libStep2"
import FormComponent from "@/components/wizard/FormComponent"
import { useDeclarationForm } from "@/hooks/useDeclarationContext"
import { useScrollTop } from "@/hooks/useScrollTop"
import { factPersonsGroups, factGoodsGroups } from "@/utils/options"
import Accordeon from "@/components/Accordeon"

const atteintesPersonnes = "Atteinte aux personnes"
const atteintesBiens = "Atteinte aux biens"

const schema = yup.object({
  factTypes: yup
    .array(yup.string())
    .min(1, "Aucun type de fait n'a été coché."),
  fgDeteriorations: yup.array(yup.string()).default(() => []),
  fgGroups: yup.array(yup.string()).default(() => []),
  fgOthers: yup.array(yup.string()).default(() => []),
  fgStealWithBreakins: yup.array(yup.string()).default(() => []),
  fgStealAggravated: yup.array(yup.string()).default(() => []),
  fgStealWithoutBreakins: yup.array(yup.string()).default(() => []),
  fpDiscriminations: yup.array(yup.string()).default(() => []),
  fpGroups: yup.array(yup.string()).default(() => []),
  fpNoRespects: yup.array(yup.string()).default(() => []),
  fpOthers: yup.array(yup.string()).default(() => []),

  fpPhysicalViolences: yup.array(yup.string()).default(() => []),
  fpPhysicalViolencesPrecision: yup
    .string()
    .when("fpPhysicalViolences", (fpPhysicalViolences, schema) => {
      return fpPhysicalViolences.includes("Autre fait qualifié de crime")
        ? schema
            .required("Le champ Autre fait qualifié de crime doit être précisé")
            .min(1, "Le champ Autre fait qualifié de crime doit être précisé")
            .max(
              255,
              ({ max }) =>
                `Le champ Autre fait ne doit pas dépasser ${max} caractères`,
            )
        : yup
            .string()
            .nullable(true)
            .transform(() => "")
    }),
  fpPsychologicalViolences: yup.array(yup.string()).default(() => []),
  fpSexualViolences: yup.array(yup.string()).default(() => []),
  fpSpokenViolences: yup.array(yup.string()).default(() => []),
})

const Step2Page = () => {
  useScrollTop()
  const {
    onSubmit: originalOnSubmit,
    handleSubmit,
    watch,
    register,
    setError,
    setValue,
    getValues,
  } = useDeclarationForm({
    defaultValuesFromState: (state) => ({
      factTypes: state?.steps?.facts?.factTypes,
      fgDeteriorations: state?.steps?.facts?.fgDeteriorations,
      fgGroups: state?.steps?.facts?.fgGroups,
      fgOthers: state?.steps?.facts?.fgOthers,
      fgStealWithBreakins: state?.steps?.facts?.fgStealWithBreakins,
      fgStealAggravated: state?.steps?.facts?.fgStealAggravated,
      fgStealWithoutBreakins: state?.steps?.facts?.fgStealWithoutBreakins,
      fpDiscriminations: state?.steps?.facts?.fpDiscriminations,
      fpGroups: state?.steps?.facts?.fpGroups,
      fpNoRespects: state?.steps?.facts?.fpNoRespects,
      fpOthers: state?.steps?.facts?.fpOthers,
      fpPhysicalViolences: state?.steps?.facts?.fpPhysicalViolences,
      fpPhysicalViolencesPrecision:
        state?.steps?.facts?.fpPhysicalViolencesPrecision,
      fpPsychologicalViolences: state?.steps?.facts?.fpPsychologicalViolences,
      fpSexualViolences: state?.steps?.facts?.fpSexualViolences,
      fpSpokenViolences: state?.steps?.facts?.fpSpokenViolences,
    }),
    resolver: yupResolver(schema),
  })

  const factTypes = watch("factTypes")
  const fpGroups = watch("fpGroups")
  const fgGroups = watch("fgGroups")

  const onSubmit = (data) => {
    if (data?.factTypes?.includes(atteintesPersonnes)) {
      const hasClicked =
        data?.fpDiscriminations.length ||
        data?.fpNoRespects.length ||
        data?.fpOthers.length ||
        data?.fpPhysicalViolences.length ||
        data?.fpPsychologicalViolences.length ||
        data?.fpSexualViolences.length ||
        data?.fpSpokenViolences.length

      if (!hasClicked) {
        setError("global", {
          message: "Il faut au moins renseigner une atteinte aux personnes.",
          type: "manual",
        })
        return
      }
    }
    if (data?.factTypes?.includes(atteintesBiens)) {
      const hasClicked =
        data?.fgDeteriorations.length ||
        data?.fgOthers.length ||
        data?.fgStealWithBreakins.length ||
        data?.fgStealWithoutBreakins.length ||
        data?.fgStealAggravated.length

      if (!hasClicked) {
        setError("global", {
          message: "Il faut au moins renseigner une atteinte aux biens.",
          type: "manual",
        })

        return
      }
    }

    originalOnSubmit(data)
  }

  return (
    <FormComponent
      onSubmit={handleSubmit(onSubmit)}
      title="Quels sont les faits ?"
    >
      <HeadTitle title="Déclaration - Étape 2 - Faits" />

      <Title2
        className="mt-12 mb-6"
        ariaLabel={"De quels types d'atteinte s'agit-il ?"}
      >
        {"De quel(s) type(s) d'atteinte s'agit-il ?"}
      </Title2>

      <p className="mb-8">
        <i>
          {
            "Vous pouvez être victime des deux types d'atteintes (sélectionner alors les deux choix)."
          }
        </i>
      </p>

      <div className="mt-4">
        <div className="mt-2 space-y-2">
          <div>
            <label className="inline-flex items-center step-2-group-label">
              <Accordeon
                expanded={
                  factTypes?.includes(atteintesPersonnes) ? true : false
                }
              />
              <input
                type="checkbox"
                className={`form-checkbox step-2-group-checkbox ${
                  factTypes?.includes(atteintesPersonnes) ? "expanded" : ""
                }`}
                name="factTypes"
                value={atteintesPersonnes}
                ref={register}
                aria-expanded={
                  factTypes?.includes(atteintesPersonnes) ? true : false
                }
              />
              <span
                className={`ml-2 step-2-group-label-span ${
                  factTypes?.includes(atteintesBiens) ? "expanded" : ""
                }`}
              >
                {atteintesPersonnes}
              </span>
            </label>

            {factTypes?.includes(atteintesPersonnes) && (
              <>
                <Groups name="fpGroups" register={register}>
                  <Group
                    value={factPersonsGroups.fpSpokenViolences.label}
                    color="text-blue-600"
                    expanded={
                      factTypes?.includes(atteintesPersonnes) &&
                      !!fpGroups?.length &&
                      fpGroups.includes(
                        factPersonsGroups.fpSpokenViolences.label,
                      )
                        ? true
                        : false
                    }
                  />
                  {factTypes?.includes(atteintesPersonnes) &&
                    !!fpGroups?.length && (
                      <>
                        {fpGroups.includes(
                          factPersonsGroups.fpSpokenViolences.label,
                        ) && (
                          <>
                            <fieldset aria-required="true">
                              <legend className="sr-only">
                                {factPersonsGroups.fpSpokenViolences.label}
                              </legend>
                              <Options
                                name="fpSpokenViolences"
                                color="text-blue-600"
                                register={register}
                              >
                                {factPersonsGroups.fpSpokenViolences.options.map(
                                  (option) => (
                                    <Option key={option.value} {...option} />
                                  ),
                                )}
                              </Options>
                            </fieldset>
                          </>
                        )}
                      </>
                    )}

                  <Group
                    value={factPersonsGroups.fpPhysicalViolences.label}
                    color="text-blue-600"
                    expanded={
                      factTypes?.includes(atteintesPersonnes) &&
                      !!fpGroups?.length &&
                      fpGroups.includes(
                        factPersonsGroups.fpPhysicalViolences.label,
                      )
                        ? true
                        : false
                    }
                  />
                  {factTypes?.includes(atteintesPersonnes) &&
                    !!fpGroups?.length && (
                      <>
                        {fpGroups.includes(
                          factPersonsGroups.fpPhysicalViolences.label,
                        ) && (
                          <>
                            <fieldset aria-required="true">
                              <legend className="sr-only">
                                {factPersonsGroups.fpPhysicalViolences.label}
                              </legend>
                              <Options
                                name="fpPhysicalViolences"
                                color="text-blue-600"
                                register={register}
                                setValue={setValue}
                                getValues={getValues}
                              >
                                {factPersonsGroups.fpPhysicalViolences.options.map(
                                  (option) => (
                                    <Option key={option.value} {...option} />
                                  ),
                                )}
                              </Options>
                            </fieldset>
                          </>
                        )}
                      </>
                    )}

                  <Group
                    value={factPersonsGroups.fpSexualViolences.label}
                    color="text-blue-600"
                    expanded={
                      factTypes?.includes(atteintesPersonnes) &&
                      !!fpGroups?.length &&
                      fpGroups.includes(
                        factPersonsGroups.fpSexualViolences.label,
                      )
                        ? true
                        : false
                    }
                  />
                  {factTypes?.includes(atteintesPersonnes) &&
                    !!fpGroups?.length && (
                      <>
                        {fpGroups.includes(
                          factPersonsGroups.fpSexualViolences.label,
                        ) && (
                          <>
                            <fieldset aria-required="true">
                              <legend className="sr-only">
                                {factPersonsGroups.fpSexualViolences.label}
                              </legend>
                              <Options
                                name="fpSexualViolences"
                                color="text-blue-600"
                                register={register}
                              >
                                {factPersonsGroups.fpSexualViolences.options.map(
                                  (option) => (
                                    <Option key={option.value} {...option} />
                                  ),
                                )}
                              </Options>
                            </fieldset>
                          </>
                        )}
                      </>
                    )}

                  <Group
                    value={factPersonsGroups.fpPsychologicalViolences.label}
                    color="text-blue-600"
                    expanded={
                      factTypes?.includes(atteintesPersonnes) &&
                      !!fpGroups?.length &&
                      fpGroups.includes(
                        factPersonsGroups.fpPsychologicalViolences.label,
                      )
                        ? true
                        : false
                    }
                  />
                  {factTypes?.includes(atteintesPersonnes) &&
                    !!fpGroups?.length && (
                      <>
                        {fpGroups.includes(
                          factPersonsGroups.fpPsychologicalViolences.label,
                        ) && (
                          <>
                            <fieldset aria-required="true">
                              <legend className="sr-only">
                                {
                                  factPersonsGroups.fpPsychologicalViolences
                                    .label
                                }
                              </legend>
                              <Options
                                name="fpPsychologicalViolences"
                                color="text-blue-600"
                                register={register}
                              >
                                {factPersonsGroups.fpPsychologicalViolences.options.map(
                                  (option) => (
                                    <Option key={option.value} {...option} />
                                  ),
                                )}
                              </Options>
                            </fieldset>
                          </>
                        )}
                      </>
                    )}

                  <Group
                    value={factPersonsGroups.fpDiscriminations.label}
                    color="text-blue-600"
                    expanded={
                      factTypes?.includes(atteintesPersonnes) &&
                      !!fpGroups?.length &&
                      fpGroups.includes(
                        factPersonsGroups.fpDiscriminations.label,
                      )
                        ? true
                        : false
                    }
                  />
                  {factTypes?.includes(atteintesPersonnes) &&
                    !!fpGroups?.length && (
                      <>
                        {fpGroups.includes(
                          factPersonsGroups.fpDiscriminations.label,
                        ) && (
                          <>
                            <fieldset aria-required="true">
                              <legend className="sr-only">
                                {factPersonsGroups.fpDiscriminations.label}
                              </legend>
                              <Options
                                name="fpDiscriminations"
                                color="text-blue-600"
                                register={register}
                              >
                                {factPersonsGroups.fpDiscriminations.options.map(
                                  (option) => (
                                    <Option key={option.value} {...option} />
                                  ),
                                )}
                              </Options>
                            </fieldset>
                          </>
                        )}
                      </>
                    )}

                  <Group
                    value={factPersonsGroups.fpNoRespects.label}
                    color="text-blue-600"
                    expanded={
                      factTypes?.includes(atteintesPersonnes) &&
                      !!fpGroups?.length &&
                      fpGroups.includes(factPersonsGroups.fpNoRespects.label)
                        ? true
                        : false
                    }
                  />
                  {factTypes?.includes(atteintesPersonnes) &&
                    !!fpGroups?.length && (
                      <>
                        {fpGroups.includes(
                          factPersonsGroups.fpNoRespects.label,
                        ) && (
                          <>
                            <fieldset aria-required="true">
                              <legend className="sr-only">
                                {factPersonsGroups.fpNoRespects.label}
                              </legend>
                              <Options
                                name="fpNoRespects"
                                color="text-blue-600"
                                register={register}
                              >
                                {factPersonsGroups.fpNoRespects.options.map(
                                  (option) => (
                                    <Option key={option.value} {...option} />
                                  ),
                                )}
                              </Options>
                            </fieldset>
                          </>
                        )}
                      </>
                    )}

                  <Group
                    value={factPersonsGroups.fpOthers.label}
                    color="text-blue-600"
                    expanded={
                      factTypes?.includes(atteintesPersonnes) &&
                      !!fpGroups?.length &&
                      fpGroups.includes(factPersonsGroups.fpOthers.label)
                        ? true
                        : false
                    }
                  />
                  {factTypes?.includes(atteintesPersonnes) &&
                    !!fpGroups?.length && (
                      <>
                        {fpGroups.includes(
                          factPersonsGroups.fpOthers.label,
                        ) && (
                          <>
                            <fieldset aria-required="true">
                              <legend className="sr-only">
                                {factPersonsGroups.fpOthers.label}
                              </legend>
                              <Options
                                name="fpOthers"
                                color="text-blue-600"
                                register={register}
                              >
                                {factPersonsGroups.fpOthers.options.map(
                                  (option) => (
                                    <Option key={option.value} {...option} />
                                  ),
                                )}
                              </Options>
                            </fieldset>
                          </>
                        )}
                      </>
                    )}
                </Groups>
              </>
            )}
          </div>
          <div>
            <label className="inline-flex items-center step-2-group-label">
              <Accordeon
                expanded={factTypes?.includes(atteintesBiens) ? true : false}
              />
              <input
                type="checkbox"
                className={`form-checkbox step-2-group-checkbox ${
                  factTypes?.includes(atteintesBiens) ? "expanded" : ""
                }`}
                name="factTypes"
                value={atteintesBiens}
                ref={register}
                aria-expanded={
                  factTypes?.includes(atteintesBiens) ? true : false
                }
              />
              <span
                className={`ml-2 step-2-group-label-span ${
                  factTypes?.includes(atteintesBiens) ? "expanded" : ""
                }`}
              >
                {atteintesBiens}
              </span>
            </label>
            {factTypes?.includes(atteintesBiens) && (
              <>
                <Groups name="fgGroups" register={register}>
                  <Group
                    value={factGoodsGroups.fgDeteriorations.label}
                    color="text-blue-600"
                    expanded={
                      factTypes?.includes(atteintesBiens) &&
                      !!fgGroups?.length &&
                      fgGroups.includes(factGoodsGroups.fgDeteriorations.label)
                        ? true
                        : false
                    }
                  />
                  {factTypes?.includes(atteintesBiens) && !!fgGroups?.length && (
                    <>
                      {fgGroups.includes(
                        factGoodsGroups.fgDeteriorations.label,
                      ) && (
                        <>
                          <fieldset aria-required="true">
                            <legend className="sr-only">
                              {factGoodsGroups.fgDeteriorations.label}
                            </legend>
                            <Options
                              name="fgDeteriorations"
                              color="text-blue-600"
                              register={register}
                            >
                              {factGoodsGroups.fgDeteriorations.options.map(
                                (option) => (
                                  <Option key={option.value} {...option} />
                                ),
                              )}
                            </Options>
                          </fieldset>
                        </>
                      )}
                    </>
                  )}

                  <Group
                    value={factGoodsGroups.fgStealWithoutBreakins.label}
                    color="text-blue-600"
                    expanded={
                      factTypes?.includes(atteintesBiens) &&
                      !!fgGroups?.length &&
                      fgGroups.includes(
                        factGoodsGroups.fgStealWithoutBreakins.label,
                      )
                        ? true
                        : false
                    }
                  />
                  {factTypes?.includes(atteintesBiens) && !!fgGroups?.length && (
                    <>
                      {fgGroups.includes(
                        factGoodsGroups.fgStealWithoutBreakins.label,
                      ) && (
                        <>
                          <fieldset aria-required="true">
                            <legend className="sr-only">
                              {factGoodsGroups.fgStealWithoutBreakins.label}
                            </legend>
                            <Options
                              name="fgStealWithoutBreakins"
                              color="text-blue-600"
                              register={register}
                            >
                              {factGoodsGroups.fgStealWithoutBreakins.options.map(
                                (option) => (
                                  <Option key={option.value} {...option} />
                                ),
                              )}
                            </Options>
                          </fieldset>
                        </>
                      )}
                    </>
                  )}

                  <Group
                    value={factGoodsGroups.fgStealWithBreakins.label}
                    color="text-blue-600"
                    expanded={
                      factTypes?.includes(atteintesBiens) &&
                      !!fgGroups?.length &&
                      fgGroups.includes(
                        factGoodsGroups.fgStealWithBreakins.label,
                      )
                        ? true
                        : false
                    }
                  />
                  {factTypes?.includes(atteintesBiens) && !!fgGroups?.length && (
                    <>
                      {fgGroups.includes(
                        factGoodsGroups.fgStealWithBreakins.label,
                      ) && (
                        <>
                          <fieldset aria-required="true">
                            <legend className="sr-only">
                              {factGoodsGroups.fgStealWithBreakins.label}
                            </legend>
                            <Options
                              name="fgStealWithBreakins"
                              color="text-blue-600"
                              register={register}
                            >
                              {factGoodsGroups.fgStealWithBreakins.options.map(
                                (option) => (
                                  <Option key={option.value} {...option} />
                                ),
                              )}
                            </Options>
                          </fieldset>
                        </>
                      )}
                    </>
                  )}
                  <Group
                    value={factGoodsGroups.fgStealAggravated.label}
                    color="text-blue-600"
                    expanded={
                      factTypes?.includes(atteintesBiens) &&
                      !!fgGroups?.length &&
                      fgGroups.includes(factGoodsGroups.fgStealAggravated.label)
                        ? true
                        : false
                    }
                  />
                  {factTypes?.includes(atteintesBiens) && !!fgGroups?.length && (
                    <>
                      {fgGroups.includes(
                        factGoodsGroups.fgStealAggravated.label,
                      ) && (
                        <>
                          <fieldset aria-required="true">
                            <legend className="sr-only">
                              {factGoodsGroups.fgStealAggravated.label}
                            </legend>
                            <Options
                              name="fgStealAggravated"
                              color="text-blue-600"
                              register={register}
                            >
                              {factGoodsGroups.fgStealAggravated.options.map(
                                (option) => (
                                  <Option key={option.value} {...option} />
                                ),
                              )}
                            </Options>
                          </fieldset>
                        </>
                      )}
                    </>
                  )}
                  <Group
                    value={factGoodsGroups.fgOthers.label}
                    color="text-blue-600"
                    expanded={
                      factTypes?.includes(atteintesBiens) &&
                      !!fgGroups?.length &&
                      fgGroups.includes(factGoodsGroups.fgOthers.label)
                        ? true
                        : false
                    }
                  />
                  {factTypes?.includes(atteintesBiens) && !!fgGroups?.length && (
                    <>
                      {fgGroups.includes(factGoodsGroups.fgOthers.label) && (
                        <>
                          <fieldset aria-required="true">
                            <legend className="sr-only">
                              {factGoodsGroups.fgOthers.label}
                            </legend>
                            <Options
                              name="fgOthers"
                              color="text-blue-600"
                              register={register}
                            >
                              {factGoodsGroups.fgOthers.options.map(
                                (option) => (
                                  <Option key={option.value} {...option} />
                                ),
                              )}
                            </Options>
                          </fieldset>
                        </>
                      )}
                    </>
                  )}
                </Groups>
              </>
            )}
          </div>
        </div>
      </div>
    </FormComponent>
  )
}

export default Step2Page
