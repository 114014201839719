import PropTypes from "prop-types"
import React from "react"

const Tab = ({ children, selected = false, ariaLabel }) =>
  selected ? (
    <li
      aria-label={ariaLabel}
      aria-current="page"
      className="inline-flex items-center justify-center w-1/2 py-3 text-base font-medium leading-none tracking-wide text-gray-100 border-b-4 border-blue-800 rounded-t sm:px-6 sm:w-auto sm:justify-start font-evolventa ariane-current"
    >
      {children}
    </li>
  ) : (
    <li
      aria-label={ariaLabel}
      className="inline-flex items-center justify-center w-1/2 py-3 text-base font-medium leading-none tracking-wide text-gray-600 border-b-2 border-gray-200 sm:px-6 sm:w-auto sm:justify-start font-evolventa"
    >
      {children}
    </li>
  )

Tab.propTypes = {
  children: PropTypes.node,
  selected: PropTypes.bool,
  ariaLabel: PropTypes.string,
}

export const Stepper = ({ step, orderedSteps = [] }) => {
  let lastIndex = 1

  return (
    <nav
      landmark="region"
      aria-label="Fil d'Ariane de la déclaration"
      className="container flex flex-col flex-wrap py-1 mx-auto text-gray-700 body-font"
    >
      <ul className="flex flex-wrap w-full mb-8 uppercase">
        {orderedSteps.map((element, index) => {
          if (element.label) {
            const formatIndex = String(lastIndex++).padStart(2, "0")
            return (
              <Tab
                key={element.name}
                ariaLabel={`${formatIndex} - ${element.label}`}
                selected={index === step - 1}
              >{`${formatIndex}/ ${element.label}`}</Tab>
            )
          }
        })}
      </ul>
    </nav>
  )
}

Stepper.propTypes = {
  orderedSteps: PropTypes.array,
  step: PropTypes.number,
}
