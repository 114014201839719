import { yupResolver } from "@hookform/resolvers"
import React from "react"
import { Controller } from "react-hook-form"
import Select from "react-select"
import * as yup from "yup"

import HeadTitle from "@/components/HeadTitle"
import { InputError } from "@/components/lib"
import FormComponent from "@/components/wizard/FormComponent"
import { useDeclarationForm } from "@/hooks/useDeclarationContext"
import { useScrollTop } from "@/hooks/useScrollTop"

import { selectConfig } from "../../../../config"
import { jobsOptions } from "@/utils/options"

import { ariaLiveMessagesFR, reactSelectCustomTheme } from "@/utils/select"

const schema = yup.object().shape({
  job: yup
    .object()
    .shape({
      label: yup.string().required("La profession est à renseigner"),
      value: yup.string().required("La profession est à renseigner"),
    })
    .nullable(true) // to consider null as an object and let required validate and displays the appropriate message
    .required("La profession est à renseigner"),
})

const Step0 = () => {
  useScrollTop()
  const { onInit, handleSubmit, errors, control } = useDeclarationForm({
    defaultValuesFromState: (state) => ({
      job: state?.steps?.job?.job || null,
    }),
    resolver: yupResolver(schema),
  })

  return (
    <FormComponent
      onSubmit={handleSubmit(onInit)}
      title="Quelle est votre profession ?"
    >
      <HeadTitle title="Déclaration - Choix de la profession" />

      <p className="mt-10 italic mandatory">
        {"Les champs obligatoires sont marqués d'un astérisque"}
      </p>
      <div className="max-w-sm m-auto mt-8">
        <label
          className={`block mb-2 text-xs font-medium tracking-wide text-gray-700 uppercase mandatory ${errors?.job && "text-red-500"
            }`}
          htmlFor="job"
        >
          Profession
        </label>

        <Controller
          as={Select}
          options={jobsOptions}
          name="job"
          inputId="job"
          aria-label="Profession (obligatoire)"
          isClearable="true"
          control={control}
          styles={selectConfig}
          placeholder="Tapez les premières lettres"
          aria-invalid={!!errors?.job?.message}
          aria-required={true}
          ariaLiveMessages={ariaLiveMessagesFR}
          theme={reactSelectCustomTheme}
          noOptionsMessage={() => "Aucun élément"}
        />

        <InputError error={errors?.job?.message} />
      </div>

      <div className="mt-12 text-center">
        <p>
          Si vous êtes membre de l&apos;une des sept professions de santé
          soumises à une inscription à un ordre professionnel,
          celui-ci aura également connaissance de votre signalement.
          Vous pouvez donner vos informations de contact en fin de
          formulaire pour être joint par votre ordre.
        </p>

        <p className="mt-8">
          <i>Temps estimé : 4 minutes</i>
        </p>
      </div>
    </FormComponent>
  )
}

export default Step0
