import React from "react"
import Accordeon from "./Accordeon"

export const Title2 = ({
  children,
  className,
  ariaLabel,
}: {
  children: React.ReactNode
  className: string
  ariaLabel
}): JSX.Element => (
  <h2
    className={`text-lg text-left font-evolventa ${className}`}
    aria-label={ariaLabel}
  >
    {children}
  </h2>
)

export const Groups = ({
  name,
  register,
  children,
}: {
  name: string
  register: () => void
  children: React.ReactNode
}): JSX.Element | null => {
  if (!children) return null

  const props = { name, register }
  
  const expandedChildren = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, props)
    }
    return null // It should not happen.
  })

  return (
    <div className="mt-4">
      <div className="mt-2 space-y-2 pl-20">{expandedChildren}</div>
    </div>
  )
}

export const Group = ({
  name,
  register,
  value,
  color,
  expanded,
}: {
  name: string
  register: () => void
  value: string
  color: string
  expanded: boolean
}): JSX.Element => {
  return (
    <div>
      <label className="inline-flex items-center step-2-group-label">
        <Accordeon expanded={expanded} />
        <input
          type="checkbox"
          className={`form-checkbox step-2-group-checkbox ${color} ${expanded ? "expanded" : ""
            }`}
          name={name}
          value={value}
          ref={register}
          aria-expanded={expanded}
        />
        <span
          className={`ml-2 step-2-group-label-span ${expanded ? "expanded" : ""
            }`}
        >
          {value}
        </span>
      </label>
    </div>
  )
}

export const Options = ({
  name,
  register,
  color = "text-blue-600",
  disabled = false,
  children,
  allChecked = [],
  setValue,
  getValues,
}: {
  name
  register
  color: string
  disabled: boolean
  children
  allChecked: string[]
  setValue: () => void
  getValues: () => any
}): JSX.Element | null => {
  if (!children) return null

  // TODO: gérer la liste des options cockés en tant que contexte plutôt que passer le paramètre allChecked

  // Iterate over children and set on them the factorized properties of their ancestor
  const expandedChildren = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        allChecked,
        color,
        disabled,
        name,
        register,
        setValue,
        getValues,
      } as React.HTMLAttributes<any>)
    }
    return child // It should not happen.
  })

  return (
    <div className="my-4">
      <div className="mt-2 space-y-2 pl-20">{expandedChildren}</div>
    </div>
  )
}

export const Option = ({
  id,
  disabled = false,
  color = "text-blue-600",
  value,
  register,
  name,
  precision,
  placeholder,
  error,
  hidden,
  info,
  allChecked,
  setValue,
  getValues,
}: {
  id: string
  disabled: boolean
  color: string
  value: string
  register: () => React.LegacyRef<HTMLInputElement> | undefined
  name: string
  precision: string
  placeholder: string
  error: string
  hidden: boolean
  info: string
  allChecked: string[]
  setValue: (name: string, value: string[]) => void
  getValues: (name: string) => string[]
}): JSX.Element => {
  const isChecked = allChecked.includes(value)

  // Texte informatif à mettre en gras
  const agissementsRepetes = "Agissements répétés"

  return (
    <div className={hidden ? "hidden" : ""}>
      <label className="inline-flex items-center">
        <input
          type="checkbox"
          className={`form-checkbox ${disabled ? "opacity-50" : ""} ${color}`}
          name={name}
          value={value}
          ref={register}
          disabled={disabled}
          defaultChecked={isChecked}
          aria-describedby={info ? `${id}Description` : ""}
        />
        <span className={`ml-2 ${disabled ? "opacity-50" : ""}`}>{value}</span>
      </label>
      {precision && (
        <>
          <div
            className={`ml-2 inline-block pb-1 border-b-4 mb-1 ${error ? "border-red-500" : "border-blue-600"
              }`}
          >
            <input
              className={`px-2 mr-3 leading-tight bg-transparent border-none focus:outline-none`}
              type="text"
              id={precision}
              name={precision}
              placeholder={placeholder ? placeholder : "Précisez ici"}
              ref={register()}
              aria-invalid={error ? "true" : "false"}
              aria-label={`${value} : ${placeholder ? placeholder : "Précisez ici"
                }`}
              autoComplete="off"
              onChange={() => {
                const values = getValues(name)?.length ? getValues(name) : []
                if (!values?.includes(value)) setValue(name, [...values, value])
              }}
            />
          </div>
        </>
      )}
      {info && (
        <div id={`${id}Description`} className="info bg-blue-200">
          {Array.isArray(info) ? (
            Array.from(info)?.map((line) => <p key={line}>{line}</p>)
          ) : (
            <>
              <p>
                {info.includes(agissementsRepetes) && (
                  <span className="font-bold">{agissementsRepetes}</span>
                )}
                {info.replace(agissementsRepetes, "")}
              </p>
            </>
          )}
        </div>
      )}
    </div>
  )
}
