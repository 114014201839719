import { yupResolver } from "@hookform/resolvers"
import { formatISO, isFuture, parseISO } from "date-fns"
import React, { useEffect, useState } from "react"
import { Controller } from "react-hook-form"
import Select from "react-select"
import * as yup from "yup"

import HeadTitle from "@/components/HeadTitle"
import { InputError, Title2 } from "@/components/lib"
import FormComponent from "@/components/wizard/FormComponent"
import { useDeclarationForm } from "@/hooks/useDeclarationContext"
import { useScrollTop } from "@/hooks/useScrollTop"

import { selectConfig } from "../../../../config"
import {
  hoursOptions,
  buildSelectOptions,
  mainEts,
  secondEts,
  linkEts,
  mainSctr,
  secondSctr,
  linkSctr,
  otherSctr,
} from "@/utils/options"

import { ariaLiveMessagesFR, reactSelectCustomTheme } from "@/utils/select"
import fetcher from "@/utils/fetcher"
import { API_URL } from "@/utils/config"

const schema = yup.object().shape({
  date: yup
    .string()
    .required("La date est à renseigner")
    .test(
      "past or present ISO date representation",
      "La date ne peut pas être future",
      function (value) {
        // TODO: utiliser isValid à la place. Car parseISO rend toujours une Date, donc la comparaison suivante est toujours fausse.
        const date = parseISO(value)
        if (date === "Invalid Date") return false
        return !isFuture(date)
      },
    ),
  hour: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string(),
    })
    .nullable(true) // to consider null as an object and let required validate and displays the appropriate message
    .required("Les heures sont à renseigner"),
  locationMain: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string(),
    })
    .nullable(true)
    .required("La catégorie d'établissement est à renseigner"),
  otherLocationMain: yup.string().when("locationMain.value", {
    is: "Autre",
    then: yup
      .string()
      .required('Le champ "Autre lieu principal" doit être précisé')
      .max(
        255,
        ({ max }) =>
          `Le champ "Autre lieu principal" ne doit pas dépasser ${max} caractères`,
      ),
  }),
  locationSecondary: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string(),
    })
    .nullable(true)
    .required("La catégorie du secteur est à renseigner"),
  otherLocationSecondary: yup.string().when("locationSecondary.value", {
    is: "Autre",
    then: yup
      .string()
      .required('Le champ "Autre secteur" doit être précisé')
      .max(
        255,
        ({ max }) =>
          `Le champ "Autre secteur" ne doit pas dépasser ${max} caractères`,
      ),
  }),
  locationThird: yup
    .array(yup.object().shape({ label: yup.string(), value: yup.string() }))
    .test(
      "is empty",
      "La catégorie du lieu est à renseigner",
      (value) => value?.length !== 0,
    )
    .nullable(true),
  otherLocationThird: yup.string().when("isOther", {
    is: (val) => Boolean(val),
    then: yup
      .string()
      .required('Le champ "Autre lieu" doit être précisé')
      .max(
        255,
        ({ max }) =>
          `Le champ "Autre lieu" ne doit pas dépasser ${max} caractères`,
      ),
  }),
  // to consider null as an object and let required validate and displays the appropriate message
  town: yup.object().default({ label: "", value: "" }).nullable(true),
})

const Step1 = () => {
  const [limitDate, setLimitDate] = useState("")

  useScrollTop()
  const { onSubmit, handleSubmit, errors, control, setValue, watch, register } =
    useDeclarationForm({
      defaultValuesFromState: (state) => ({
        date:
          state?.steps?.dateLocation?.date ||
          formatISO(new Date(), { representation: "date" }),
        hour: state?.steps?.dateLocation?.hour || hoursOptions?.[0],
        locationMain: state?.steps?.dateLocation?.locationMain || null,
        otherLocationMain: state?.steps?.dateLocation?.otherLocationMain,
        locationSecondary:
          state?.steps?.dateLocation?.locationSecondary || null,
        otherLocationSecondary:
          state?.steps?.dateLocation?.otherLocationSecondary,
        locationThird: state?.steps?.dateLocation?.locationThird || [],
        otherLocationThird: state?.steps?.dateLocation?.otherLocationThird,
        town: state?.steps?.dateLocation?.town || null,
        isOther: state?.steps?.dateLocation?.isOther,
      }),
      resolver: yupResolver(schema),
    })

  const getAllParams = async () => {
    try {
      const { data } = await fetcher(`${API_URL}/params`, {
        headers: { "Content-Type": "application/json" },
        method: "GET",
      })
      const params = data.reduce((prev, curr) => {
        return { ...prev, [curr.name]: curr.value }
      }, {})
      setLimitDate(Object.values(params)[0])
    } catch (e) {
      console.error("Error Get Params:", e)
    }
  }

  const locationMain = watch("locationMain")
  const locationSecondary = watch("locationSecondary")
  const isOther = watch("isOther")

  useEffect(() => {
    if (locationSecondary?.value !== "Autre") {
      setValue("otherLocationSecondary", "")
    }
  }, [setValue, locationSecondary])

  useEffect(() => {
    if (!isOther) {
      setValue("otherLocationThird", "")
    }
  }, [setValue, isOther])

  useEffect(() => {
    getAllParams()
  }, [])

  return (
    <FormComponent
      onSubmit={handleSubmit(onSubmit)}
      title="Où la violence a-t-elle eu lieu ?"
    >
      <HeadTitle title="Déclaration - Étape 1 - Date et lieu" />
      <p className="mt-10 italic mandatory">
        {"Les champs obligatoires sont marqués d'un astérisque"}
      </p>
      <fieldset aria-required="true">
        <Title2 className="mt-12 mb-8">
          <legend>{"Quand l'événement s'est-il déroulé ?"}</legend>
        </Title2>

        <div className="flex mt-4 space-x-6">
          <div className="flex-1">
            <label
              className="block mb-2 text-xs font-medium tracking-wide text-gray-700 uppercase mandatory"
              htmlFor="date"
            >
              Date
            </label>
            <input
              className="w-full form-input"
              type="date"
              id="date"
              name="date"
              min={limitDate}
              ref={register}
              aria-invalid={!!errors?.date?.message}
              aria-required="true"
            />

            <InputError error={errors?.date?.message} />
          </div>

          <div className="flex-1">
            <label
              className="block mb-2 text-xs font-medium tracking-wide text-gray-700 uppercase mandatory"
              htmlFor="hour"
            >
              Horaire
            </label>

            <Controller
              aria-required={true}
              aria-label="Horaire (obligatoire)"
              as={Select}
              options={hoursOptions}
              name="hour"
              inputId="hour"
              control={control}
              styles={selectConfig}
              required
              ariaLiveMessages={ariaLiveMessagesFR}
              theme={reactSelectCustomTheme}
              noOptionsMessage={() => "Aucun élément"}
            />
          </div>
        </div>
      </fieldset>

      <fieldset aria-required="true">
        <fieldset>
          <Title2 className="mt-12">
            <legend className="mandatory">
              Dans quel type d&apos;établissement ?
            </legend>
          </Title2>

          <div className="mt-4 grow">
            <label
              className={`block mb-2 text-xs font-medium tracking-wide text-gray-700 uppercase ${
                errors?.locationMain && "text-red-500"
              }`}
              htmlFor="locationMain"
            >
              Catégorie d&apos;établissement
            </label>
            <Controller
              aria-required={true}
              aria-label="Lieu principal (obligatoire)"
              as={Select}
              options={Object.entries(mainEts).map((ets) => {
                const [key, value] = ets
                const listEts = linkEts[key].map((etsId) => secondEts[etsId])
                return { label: value, options: buildSelectOptions(listEts) }
              })}
              name="locationMain"
              inputId="locationMain"
              control={control}
              styles={selectConfig}
              isClearable={true}
              placeholder="Choisir..."
              ariaLiveMessages={ariaLiveMessagesFR}
              theme={reactSelectCustomTheme}
              noOptionsMessage={() => "Aucun élément"}
            />
          </div>
        </fieldset>

        {locationMain && (
          <fieldset>
            <Title2 className="mt-12">
              <legend className="mandatory">
                Dans quel secteur précisément ?
              </legend>
            </Title2>

            <div className="mt-4 grow">
              <label
                className={`block mb-2 text-xs font-medium tracking-wide text-gray-700 uppercase ${
                  errors?.locationSecondary && "text-red-500"
                }`}
                htmlFor="locationSecondary"
              >
                Catégorie du secteur
              </label>
              <Controller
                aria-required={true}
                aria-label="Secteur principal (obligatoire)"
                as={Select}
                options={Object.entries(mainSctr).map((sctr) => {
                  const [key, value] = sctr
                  const listSctr = linkSctr[key].map(
                    (sctrId) => secondSctr[sctrId],
                  )
                  return { label: value, options: buildSelectOptions(listSctr) }
                })}
                name="locationSecondary"
                inputId="locationSecondary"
                control={control}
                styles={selectConfig}
                isClearable={true}
                placeholder="Choisir..."
                ariaLiveMessages={ariaLiveMessagesFR}
                theme={reactSelectCustomTheme}
                noOptionsMessage={() => "Aucun élément"}
              />
            </div>
            {locationSecondary?.value == "Autre" && (
              <>
                <div className="mt-4">
                  <label
                    className="inline-flex items-center"
                    htmlFor="otherLocationSecondary"
                  >
                    <span className="ml-2 mandatory">Autre secteur :</span>
                  </label>
                  <div
                    className={`inline-block py-2 border-b-2  ${
                      errors?.otherLocationSecondary?.message
                        ? "border-red-500"
                        : "border-blue-400"
                    }`}
                  >
                    <input
                      className={`px-2 mr-3 leading-tight bg-transparent border-none focus:outline-none`}
                      type="text"
                      id="otherLocationSecondary"
                      name="otherLocationSecondary"
                      placeholder="Précisez ici"
                      ref={register}
                      aria-required={true}
                      aria-invalid={
                        errors?.otherLocationSecondary?.message
                          ? "true"
                          : "false"
                      }
                    />
                  </div>
                </div>

                <InputError error={errors?.otherLocationSecondary?.message} />
              </>
            )}
          </fieldset>
        )}
        {locationSecondary && (
          <fieldset className="mb-16">
            <Title2 className="mt-12">
              <legend className="mandatory">
                Dans quel lieu précisément ?
              </legend>
            </Title2>
            <div className="mt-4 grow">
              <label
                className={`block mb-2 text-xs font-medium tracking-wide text-gray-700 uppercase ${
                  errors?.locationThird && "text-red-500"
                }`}
                htmlFor="locationThird"
              >
                Catégorie de lieu {!isOther && `(choix multiples)`}
              </label>
              {!isOther && (
                <Controller
                  aria-required={true}
                  aria-label="Secteur principal (obligatoire)"
                  as={Select}
                  options={Object.entries(otherSctr).map((sctr) => {
                    const [key, value] = sctr
                    return { label: value, value }
                  })}
                  isMulti
                  disabled={true}
                  name="locationThird"
                  inputId="locationThird"
                  control={control}
                  styles={selectConfig}
                  isClearable={true}
                  placeholder="Choisir..."
                  ariaLiveMessages={ariaLiveMessagesFR}
                  theme={reactSelectCustomTheme}
                  noOptionsMessage={() => "Aucun élément"}
                />
              )}
            </div>
            <div className="mt-2 space-y-2">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  className={`form-checkbox`}
                  value={isOther}
                  id="isOther"
                  name="isOther"
                  ref={register}
                  onChange={() => {
                    setValue("locationThird", [])
                    setValue("isOther", !isOther)
                  }}
                />
                <span className="ml-2 mandatory">Autre lieu :</span>
              </label>
              <div
                className={`inline-block py-2 border-b-2  ${
                  errors?.otherLocationThird?.message
                    ? "border-red-500"
                    : "border-blue-400"
                }`}
              >
                <input
                  className={`px-2 mr-3 leading-tight bg-transparent border-none focus:outline-none`}
                  type="text"
                  id="otherLocationThird"
                  name="otherLocationThird"
                  placeholder="Précisez ici"
                  ref={register}
                  disabled={!isOther}
                  aria-required={true}
                  aria-invalid={
                    errors?.otherLocationThird?.message ? "true" : "false"
                  }
                />
              </div>
              <InputError error={errors?.otherLocationThird?.message} />
            </div>
          </fieldset>
        )}
      </fieldset>
    </FormComponent>
  )
}

export default Step1
