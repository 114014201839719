import { yupResolver } from "@hookform/resolvers"
import React from "react"
import * as yup from "yup"

import HeadTitle from "@/components/HeadTitle"
import { InputError, Title2 } from "@/components/lib"
import FormComponent from "@/components/wizard/FormComponent"
import { useDeclarationForm } from "@/hooks/useDeclarationContext"
import { useScrollTop } from "@/hooks/useScrollTop"
import Links from "@/components/LinksStep5"

const schema = yup.object({
  declarantContactAgreement: yup
    .string()
    .nullable()
    .required(
      "L'accord (ou non) sur l'envoi des coordonnées est à renseigner.",
    ),
  declarantEmail: yup.string().when("declarantContactAgreement", {
    is: "true",
    otherwise: (schema) => schema.transform(() => ""),
    then: (schema) =>
      schema
        .required("L'e-mail est à renseigner")
        .email("L'e-mail est mal formé"),
  }),
  declarantExternalId: yup.string().when("declarantContactAgreement", {
    is: "true",
    otherwise: (schema) => schema.transform(() => ""),
    then: (schema) =>
      schema
        .required("Le n° RPPS/Adeli est à renseigner")
        .matches(
          /^[0-9]{9}$|^[0-9]{11}$/g,
          "Le n° RPPS/Adeli est à 9 ou 11 chiffres",
        ),
  }),
  declarantNames: yup.string().when("declarantContactAgreement", {
    is: "true",
    otherwise: (schema) => schema.transform(() => ""),
    then: (schema) => schema.required("Le nom est à renseigner"),
  }),
  declarantTel: yup.string().when("declarantContactAgreement", {
    is: "true",
    otherwise: (schema) => schema.transform(() => ""),
    then: (schema) =>
      schema
        .required("Le téléphone est à renseigner")
        .matches(/[0-9]{10}/g, "Le numéro de téléphone est mal formé"),
  }),
  description: yup.string().required("La description doit être renseignée"),
})

const Step5Page = () => {
  useScrollTop()
  const { onSubmit, handleSubmit, errors, setValue, watch, register } =
    useDeclarationForm({
      defaultValuesFromState: (state) => ({
        declarantContactAgreement:
          state?.steps?.precision?.declarantContactAgreement,
        declarantEmail: state?.steps?.precision?.declarantEmail,
        declarantExternalId: state?.steps?.precision?.declarantExternalId,
        declarantNames: state?.steps?.precision?.declarantNames,
        declarantTel: state?.steps?.precision?.declarantTel,
        description: state?.steps?.precision?.description,
      }),

      resolver: yupResolver(schema),
    })

  const declarantContactAgreement = watch("declarantContactAgreement")

  React.useEffect(() => {
    if (declarantContactAgreement === "false") {
      setValue("declarantEmail", "")
      setValue("declarantExternalId", "")
      setValue("declarantNames", "")
      setValue("declarantTel", "")
    }
  }, [declarantContactAgreement, setValue])

  return (
    <FormComponent
      onSubmit={handleSubmit(onSubmit)}
      title="Pourriez-vous apporter quelques précisions ?"
    >
      <HeadTitle title="Déclaration - Étape 5 - Précisions" />

      <p className="mt-10 italic mandatory">
        {"Les champs obligatoires sont marqués d'un astérisque"}
      </p>
      <Title2 className="mt-8 mb-4 mandatory">
        {"Description plus détaillée de l'événement"}
      </Title2>

      <div className="border p-3 font-bold rounded border-4 border-red-700 align-middle mb-6">
        <em className="text-blue-700" id="precisions">
          <p className="text-center">ATTENTION ! </p>
          <p>
            Dans la description de l’événement ne faites figurer aucune mention
            permettant d’identifier une personne : prénom, nom de famille,
            surnom, date et lieu de naissance, un quelconque numéro (téléphone,
            plaque d’immatriculation, etc.). Vous pouvez formuler ainsi : « M.
            ou Mme, le patient ou la patiente, le fils/la fille du patient, le
            Dr X, l’IDE Y, etc. a fait ceci, a dit cela ».
          </p>
          <br />
          <p>
            Ajouter si possible votre ressenti, le contexte, les gestes,
            attitudes et comportements ainsi que les expressions et les mots
            précis prononcés pour intimider, insulter, outrager, menacer,
            violenter.
          </p>
        </em>
      </div>

      <label htmlFor="description" className="italic">
        {
          "Description de l'événement en ajoutant, si besoin, votre ressenti et le contexte."
        }
      </label>

      <textarea
        className={`fr-input w-full mt-8 form-textarea rounded ${
          errors?.description?.message ? "border-red-500" : "border-gray-300"
        }`}
        name="description"
        ref={register}
        style={{ height: "300px" }}
        id="description"
        aria-describedby="precisions"
        aria-required="true"
      />
      <fieldset aria-required="true">
        <legend>
          <Title2 className="mt-10 mandatory">
            {"Quelles sont vos coordonnées ?"}
          </Title2>
        </legend>

        <div className="mt-10">
          <div>
            <label className="inline-flex items-center">
              <input
                type="radio"
                className="w-5 h-5 form-radio"
                name="declarantContactAgreement"
                value="true"
                ref={register}
              />
              <span className="ml-5">
                J&apos;accepte de transmettre mes coordonnées et les réponses au
                questionnaire à l&apos;ONVS et/ou à l&apos;ordre dont je
                dépends. Cela permettra à un référent violence de l&apos;ordre
                de me contacter et de m&apos;accompagner si j&apos;ai besoin
                d&apos;écoute, de conseils ou d&apos;aide juridique.
              </span>
            </label>
          </div>

          {declarantContactAgreement === "true" && (
            <>
              <div className="flex mt-6 ml-10 space-x-6">
                <div className="flex-1">
                  <label
                    className={`block mb-2 text-xs font-medium tracking-wide text-gray-700 uppercase mandatory ${
                      errors?.declarantNames && "text-red-500"
                    }`}
                    htmlFor="declarantNames"
                    aria-label="Prénom et Nom"
                  >
                    Prénom & Nom
                  </label>
                  <input
                    type="text"
                    id="declarantNames"
                    name="declarantNames"
                    ref={register}
                    className={`w-full form-input ${
                      errors?.declarantNames && "border-red-600"
                    }`}
                    aria-invalid={!!errors?.declarantNames?.message}
                    aria-required="true"
                  />

                  <InputError error={errors?.declarantNames?.message} />
                </div>
                <div className="flex-1">
                  <label
                    className={`block mb-2 text-xs font-medium tracking-wide text-gray-700 uppercase mandatory ${
                      errors?.declarantExternalId && "text-red-500"
                    }`}
                    htmlFor="declarantExternalId"
                  >
                    Numéro RPPS ou ADELI
                  </label>
                  <input
                    type="tel"
                    id="declarantExternalId"
                    name="declarantExternalId"
                    placeholder="Numéro à 9 ou 11 chiffres"
                    ref={register}
                    className={`w-full form-input ${
                      errors?.declarantExternalId && "border-red-600"
                    }`}
                    aria-invalid={!!errors?.declarantExternalId?.message}
                    aria-required="true"
                  />
                  <InputError error={errors?.declarantExternalId?.message} />
                </div>
              </div>

              <div className="flex mt-8 ml-10 space-x-6">
                <div className="flex-1">
                  <label
                    className={`block mb-2 text-xs font-medium tracking-wide text-gray-700 uppercase mandatory ${
                      errors?.declarantEmail && "text-red-500"
                    }`}
                    htmlFor="declarantEmail"
                  >
                    E-mail
                  </label>
                  <input
                    type="email"
                    id="declarantEmail"
                    name="declarantEmail"
                    placeholder="adresse@mail.com"
                    ref={register}
                    className={`w-full form-input ${
                      errors?.declarantEmail && "border-red-600"
                    }`}
                    aria-invalid={!!errors?.declarantEmail?.message}
                    aria-required="true"
                    lang="en"
                  />
                  <InputError error={errors?.declarantEmail?.message} />
                </div>
                <div className="flex-1">
                  <label
                    className={`block mb-2 text-xs font-medium tracking-wide text-gray-700 uppercase mandatory ${
                      errors?.declarantTel && "text-red-500"
                    }`}
                    htmlFor="declarantTel"
                  >
                    Numéro de téléphone
                  </label>
                  <input
                    type="tel"
                    id="declarantTel"
                    name="declarantTel"
                    ref={register}
                    className={`w-full form-input ${
                      errors?.declarantTel && "border-red-600"
                    }`}
                    aria-invalid={!!errors?.declarantTel?.message}
                    aria-required="true"
                  />
                  <InputError error={errors?.declarantTel?.message} />
                </div>
              </div>
            </>
          )}

          <div className="mt-8">
            <label className="inline-flex items-center">
              <input
                type="radio"
                className="w-5 h-5 form-radio"
                name="declarantContactAgreement"
                value="false"
                ref={register}
              />
              <span className="ml-5">
                Je ne souhaite pas transmettre mes coordonnées. Mes réponses
                seront transmises de façon anonyme à l&apos;ONVS et/ou
                l&apos;ordre dont je dépends.
              </span>
            </label>
          </div>
        </div>
      </fieldset>
      <Links />
      <p className="mt-10 text-md font-bold">
        Attention ! Une fois envoyée, votre déclaration ne sera plus modifiable.
      </p>
    </FormComponent>
  )
}

export default Step5Page
